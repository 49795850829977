var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-comment-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'spam_timeout',
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          }
        },
      }}}),(_vm.module.model.params.spam_timeout > 0)?_c('nested-content',[_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"message-editor-placeholders":_vm.BASE_PLACEHOLDERS,"title-key":"spam_punish_action","tags":_vm.advancedLicenseTag},model:{value:(_vm.module.model.params.spam_punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "spam_punish_action", $$v)},expression:"module.model.params.spam_punish_action"}})],1):_vm._e(),_c('time-unit-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'allow_timeout',
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          }
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'close_messages',
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.info/cabinet',
          'hasAnchor': true,
          'hasMedia': true
        },
      }}}),_c('black-white-list-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'stopWords',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'hasAnchor': true,
          'tariffTags': _vm.advancedLicenseTag
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }